import React from 'react';
import AdUnitBlock from "./blocks/AdUnitBlock";
import BannerAdBlock from './blocks/BannerAdBlock';
import BlockQuoteBlock from "./blocks/BlockQuoteBlock";
import CallToActionBlock from "./blocks/CallToActionBlock";
import HalfPageBannerBlock from './blocks/HalfPageBannerBlock';
import ImageBlock from "./blocks/ImageBlock";
import PhoneCtaBlock from "./blocks/PhoneCtaBlock";
import RichTextBlock from "./blocks/RichTextBlock";
import TestimonialCtaBlock from './blocks/TestimonialCtaBlock';
import TextBlock from "./blocks/TextBlock";
import VerticalImageBlock from "./blocks/VerticalImageBlock";
import YoutubeVideoBlock from "./blocks/YoutubeVideoBlock";

const blockMapping = {
  banner_ad: BannerAdBlock,
  block_quote: BlockQuoteBlock,
  call_to_action: CallToActionBlock,
  half_page_banner: HalfPageBannerBlock,
  image: ImageBlock,
  rich_text: RichTextBlock,
  testimonial_block_cta: TestimonialCtaBlock,
  text: TextBlock,
  vertical_image: VerticalImageBlock,
  youtube_video: YoutubeVideoBlock,
  ad_unit: AdUnitBlock,
  phone_cta: PhoneCtaBlock
};

const processArticleBlock = (block) => {
  for (const prop in blockMapping) {
    if (block.hasOwnProperty(prop)) {
      const Component = blockMapping[prop];
      return <Component data={block[prop]} />;
    }
  }

  return null;
};

export default function ArticleBlock({ content }) {
  return (
    processArticleBlock(content)
  );
}
