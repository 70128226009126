import dayjs from "dayjs";

export function getBrowserTimezone() {
  //TODO: Intl only avail in modern browsers(Prob fine)
  //TODO: Do we store preferred TZ on User model?
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function formatLocalDateSimple(datetime) {
  return dayjs(datetime).format("MM/DD/YYYY");
}

export function surveyDate(datetime) {
  return dayjs(datetime).format("MMM-DD-YYYY");
}

export function articleDate(datetime) {
  return dayjs(datetime).format("MM.DD.YY");
}

export function formatMessageDate({ datetime, today, yesterday }) {
  if (today && yesterday) {
    const time = dayjs(datetime);
    const isToday = time.format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD');
    const isYesterday = time.format('YYYY-MM-DD') === dayjs().subtract(1, 'day').format('YYYY-MM-DD');

    let date;
    if (isToday) {
      date = today;
    } else if (isYesterday) {
      date = yesterday;
    } else {
      date = time.format('MMM DD');
    }

    const hour = time.format('hh:mmA');
    return `${date} ${hour}`;
  }

  return dayjs(datetime).format("MMM DD hh:mmA");
}

export function inboundDates(inbound, fields) {
  const result = {...inbound};

  for (const field of fields) {
    result[field] = result[field] && dayjs(result[field]).format("MM/DD/YYYY");
  }

  return result;
}

export function outboundDates(outbound, fields) {
  const result = {...outbound};

  for (const field of fields) {
    result[field] = result[field] && dayjs(result[field], "MM/DD/YYYY").format("YYYY-MM-DD");
  }

  return result;
}
