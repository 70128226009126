import React, { useState } from 'react';
import cx from "classnames";
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { useTranslation } from '@/hooks/translation';
import styles from './article.module.scss';

export function SearchBar(props) {
  const { className, initialSearchTerm, placeholder } = props;
  const router = useRouter();
  const { t } = useTranslation();

  const [query, setQuery] = useState('');

  React.useEffect(() => {
    setQuery(initialSearchTerm || '');
  }, [initialSearchTerm]);

  function handleSubmit(e) {
    e.preventDefault();
    if (!query.match(/\S/)) return;

    router.push(`/articles/search?q=${query}`);
  }

  function handleChange(e) {
    setQuery(e.target.value);
  }

  return (
    <Form onSubmit={handleSubmit} className={cx(styles.searchBar, className)}>
      <Form.Control
        type="text"
        placeholder={placeholder}
        value={query}
        onChange={handleChange}
        className={className}
      />
    </Form>
  );
}

SearchBar.propTypes = {
  className: PropTypes.string,
  initialSearchTerm: PropTypes.string,
  placeholder: PropTypes.string,
};
