import React from 'react';
import cx from "classnames";
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { ArticleFeed, FilterBar, SearchBar } from '@/components/Articles';
import MetaHead from '@/components/MetaHead';
import { useSearchArticlesResults } from '@/hooks/search';
import { useTranslation } from '@/hooks/translation';
import useWindowSize from '@/hooks/useWindowSize';
import { useCurrentUser } from '@separate/hooks/user';
import Pagination from '../Pagination';
import { ArticleListItem } from './ArticleListItem';
import ArticleListItemDesktop from './ArticleListItemDesktop';
import styles from './articlePage.module.scss';

export default function ArticlePage({ articles, category, currentPage, featured, locale, numPages }) {
  const { user, isLoggedIn, isLoading: isUserLoading } = useCurrentUser();
  const { query } = useRouter();
  const { t } = useTranslation();
  const isOnDesktop = useWindowSize();

  const isSearch = category === 'search';
  const searchString = isSearch ? query.q : '';
  const { isLoading, searchResults } = useSearchArticlesResults(searchString, locale);

  const didSearch = isSearch && !isLoading;
  const searchResultsCount = searchResults?.length || 0;

  const articleCategoriesMetaItems = t('meta.article_categories', { returnObjects: true });
  const META_TITLE = category && category !== 'search' ? articleCategoriesMetaItems[category].title : t('meta.articles.title');
  const META_DESCRIPTION = category && category !== 'search' ? articleCategoriesMetaItems[category].description : t('meta.articles.description');

  function pagePath(pageNum) {
    const categoryPath = category ? category + '/' : '';

    return pageNum === 1 || pageNum === undefined
      ? `/articles/${categoryPath}`
      : `/articles/${categoryPath}page/${pageNum}`;
  }

  const articleListComponent = (item) => {
    return isOnDesktop ? (
      <ArticleListItemDesktop
        key={item.uid}
        article={item}
        className={cx(styles.article)}
        userIsPremium={user?.is_premium_member}
        isLoading={isUserLoading}
        isLoggedIn={isLoggedIn}
      />
    ) : (
      <ArticleListItem
        key={item.uid}
        article={item}
        userIsPremium={user?.is_premium_member}
        isLoading={isUserLoading}
        isLoggedIn={isLoggedIn}
      />
    );
  };

  return (
    <div className={styles.wrapper}>
      <MetaHead title={META_TITLE} description={META_DESCRIPTION} type="article" />
      <Row className="mb-3 justify-content-center">
        <Col sm={12}>
          <h1 className={styles.greetings}>{t("articles.index.articles_title")}</h1>
          <SearchBar initialSearchTerm={searchString} placeholder={t('articles.search')} />
          {didSearch && !searchResultsCount && (
            <p>
              {`${t('articles.no_results')} ${searchString}`}
            </p>
          )}
        </Col>
      </Row>

      <Row className="mb-3 justify-content-center">
        <Col sm={12}>
          <h2 className={styles.header}>{t("articles.index.filter_by_category")}</h2>
          <FilterBar selected={category} />
        </Col>
      </Row>

      <Row className={cx("mb-3 justify-content-center", styles.rowWrapper)}>
        <Col sm={12}>
          {didSearch && searchResultsCount > 0 && (
            <>
              <h2 className={styles.header}>{`${t('articles.search_results')} ${searchString}`}</h2>
              <ArticleFeed articles={searchResults} searchResultsCount={searchResultsCount} asGrid={isOnDesktop} />
            </>
          )}
          {!didSearch && (
            <>
              <h2 className={styles.header}>{t('articles.index.featured')}</h2>
              <ArticleFeed articles={featured} asGrid={isOnDesktop} />
            </>
          )}
        </Col>
      </Row>

      {!didSearch && (
        <Row className={cx("mb-3 justify-content-center", styles.rowWrapper)}>
          <Col sm={12}>
            <h2 className={styles.header}>{t("articles.index.all")}</h2>
          </Col>
          <div>
            <div className={cx(isOnDesktop && styles.articleListWrapper)}>
              {articles.map((item) => articleListComponent(item))}
            </div>
            <Pagination
              currentPage={currentPage ? Number(currentPage): 1}
              siblingCount={1}
              pageCount={numPages}
              pageSize={10}
              pagePath={pagePath}
            />
          </div>
        </Row>
      )}
    </div>
  );
}

ArticlePage.propTypes = {
  articles: PropTypes.array.isRequired,
  category: PropTypes.string,
  currentPage: PropTypes.number,
  featured: PropTypes.array.isRequired,
  locale: PropTypes.string,
  numPages: PropTypes.number
};
