import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import LinkButton from '@/components/LinkButton';
import styles from './articleBlock.module.scss';

export default function HalfPageBannerBlock({ data }) {
  const {
    bullet_point_1: bulletPoint1,
    bullet_point_2: bulletPoint2,
    bullet_point_3: bulletPoint3,
    cta_button: ctaButton,
    header,
  } = data;

  const { href, title } = ctaButton;
  const checkmarkImage = require('@images/half-page-banner-checkmark.png').default.src;

  return (
    <div className={cx(styles.halfPageBanner, 'my-5')}>
      <h2>{header}</h2>

      <div className={cx(styles.bulletPoints, 'mt-4')}>
        <div className='d-flex align-items-center'>
          <img src={checkmarkImage} alt="checkmark" />
          <p>{bulletPoint1}</p>
        </div>

        <div className='d-flex align-items-center'>
          <img src={checkmarkImage} alt="checkmark" />
          <p>{bulletPoint2}</p>
        </div>

        <div className='d-flex align-items-center'>
          <img src={checkmarkImage} alt="checkmark" />
          <p>{bulletPoint3}</p>
        </div>
      </div>

      <div className='d-flex align-items-center justify-content-center mt-4'>
        <LinkButton
          href={href}
          className={cx(styles.ctaButton, 'mx-auto')}
          isExternal
        >
          {title}
        </LinkButton>
      </div>
    </div>
  );
}

HalfPageBannerBlock.propTypes = {
  data: PropTypes.shape({
    bullet_point_1: PropTypes.string,
    bullet_point_2: PropTypes.string,
    bullet_point_3: PropTypes.string,
    cta_button: PropTypes.shape({
      title: PropTypes.string,
      href: PropTypes.string,
    }),
    header: PropTypes.string,
  }),
};
