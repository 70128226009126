import { useQuery } from 'react-query';
import { searchArticles } from '@/api/contentStackSearch';
import { prioritizeArticles } from '@/utilities/articles';

export function useSearchArticlesResults(searchString, locale) {
  const result = useQuery({
    queryKey: [searchString, locale],
    queryFn: () => searchArticles(searchString, locale),
  });

  const { isLoading, data } = result;
  const filteredData = prioritizeArticles(data?.filter(article => !article.archive));
  return { isLoading, searchResults: filteredData };
};
