import * as React from 'react';
import PropTypes from 'prop-types';
import LinkButton from '@/components/LinkButton';
import Modal from '@/components/Modal';
import { useTranslation } from "@/hooks/translation";
import LoginModal from '@separate/components/LoginModal';
import styles from './articleCard.module.scss';

export default function Actions(props) {
  const { showMessage, dismissMessage, showLogin, dismissLogin, handleLogin, isLoggedIn, ...extraProps } = props;
  const { t } = useTranslation();

  return (
    <>
      <Modal
        show={showMessage}
        onHide={dismissMessage}
        className={styles.lockedMessage}
        backdropClassName={styles.modalBackdrop}
      >
        <h3 className="mb-3">{t(`articles.premium.message.title`)}</h3>
        <div className="mb-3">
          <LinkButton className="py-2 px-3" href="/premium">{t(`articles.premium.message.cta`)}</LinkButton>
        </div>
        {!isLoggedIn &&
          <p>{t(`articles.premium.message.member`)} <a onClick={handleLogin}>{t(`articles.premium.message.link`)}</a></p>
        }
      </Modal>
      <LoginModal show={showLogin} onHide={dismissLogin} />
    </>
  );
}

Actions.propTypes = {
  showMessage: PropTypes.bool,
  dismissMessage: PropTypes.func,
  showLogin: PropTypes.bool,
  dismissLogin: PropTypes.func,
  handleLogin: PropTypes.func,
  isLoggedIn: PropTypes.bool,
};
