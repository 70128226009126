import articles from './allArticles.preval';

const ARTICLE_ORDER_PRIORITY = { High: 1, Medium: 2, Low: 3 };

export function allArticles() {
  return articles;
}

function getPriorityVal(priority) {
  const value = ARTICLE_ORDER_PRIORITY[priority];

  return value ? value : 4;
}

export function prioritizeArticles(articles) {
  if (!articles) return;

  return articles?.sort((a, b) => {
    return getPriorityVal(a.article_priority) - getPriorityVal(b.article_priority);
  });
}
