import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import StandardCard from '@/components/cards/StandardCard';
import LinkButton from '@/components/LinkButton';
import { useTranslation } from '@/hooks/translation';
import styles from './podercard.module.scss';

export default function PodercardCard({ imageAlt, className, analyticsProperties }) {
  const { t } = useTranslation();

  return (
    <>
      <StandardCard
        className={cx(styles.card, className)}
      >
        <div className={cx(styles.podercardContent, "p-2")}>
          <h3 className="mb-3">{t("home.podercard.digital_banking")}</h3>
          <LinkButton href="/podercard" className={styles.button} analyticsProperties={analyticsProperties}>
            {t("home.podercard.sign_up")}
          </LinkButton>
        </div>
        <div className={styles.poderCardImage}>
          <img
            alt={imageAlt ? imageAlt : "PODERcard"}
            src="https://images.contentstack.io/v3/assets/blt224b6cd2a9d5dbf3/blt0af0eb7e45530ae4/629e562d61670f0fb5dd1830/PODERcard_Debit.png"
          />
        </div>
      </StandardCard>
    </>
  );
}

PodercardCard.propTypes = {
  imageAlt: PropTypes.string,
  className: PropTypes.string,
  analyticsProperties: PropTypes.object,
};
